import React from "react";
import { Link } from "gatsby";
import Helmet from "react-helmet";
import SEO from "../components/SEO";
import Layout from "../layouts/index";
import logo from "../images/logo.png";

const NotFound = (props) => {
  const path = props.uri;

  return (
    <Layout bodyClass="page-services" path={path}>
      <SEO title="Page not found" keywords={['Central East', 'Central East Corporation', 'Central East Corporation LTD']}/>
      <Helmet>
        <meta
          name="description"
          content="Central East Corporation Ltd is a Hong Kong based sourcing organization and a trading company with over 3 years of experience in purchasing and sourcing in Asia."
        />
      </Helmet>

      <div className="container pb-6">
        <div className="row justify-content-center">
          <div className="col-12 mt-8 d-flex flex-column align-items-center">
            <img alt="CEC Logo" src={logo} className="d-block" />
            <h2 className="text-center title-1 mb-3 mt-3">Page not found</h2>
            <div className="col-12 text-center">
            <Link to="/" className="button button-primary btn-lg mt-2">
              Go to Home page
            </Link>
          </div>
          </div>
        </div>
        </div>
    </Layout>
  );
};

export default NotFound;
